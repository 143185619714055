import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function AddGallery() {
  useEffect(() => {
    document.title = "Add Gallery | Amin Panel";
  }, []);

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
    if (!user_name) {
      location("/");
    }
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
   
      const formData = new FormData();
      formData.append("testImage", selectedFile);

      formData.append("method", "gallery");
      formData.append("submethod", "insert");

      const headers = {
        Authorization: `${process.env.REACT_APP_KEY}`,
        "Content-Type": "multipart/form-data",
      };

      // Make API request
      axios
        .post(`${process.env.REACT_APP_BACKEND_LINK}`, formData, { headers })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          location("/gallery-list");
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    // }
  };

  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />
            <main className="flex-grow p-6">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Add Gallery Images
                </h4>
              </div>

              <div className="mt-6">
                <div className="card">
                  <div className="p-6">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Image *
                      </label>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <br/>
                    <div className="flex justify-center mb-6">
                      <button
                        className="btn w-full text-white bg-primary"
                        onClick={handleSubmit}
                      >
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
