import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function Inquiry() {
  useEffect(() => {
    document.title = "Inquiry List | Amin Panel";
  }, []);

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
    if (!user_name) {
      location("/");
    }
  });

  const [Inquirylistdata, setInquirylistdata] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = Inquirylistdata?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  function ViewInquirys() {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "inquiry",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("View Inquiry List Data", res.data.dataLength);
        setInquirylistdata(res.data.data);
      });
  }

  function deleteInquiry(id) {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "inquiry",
        submethod: "delete",
        key: process.env.REACT_APP_KEY,
        id: id,
      })
      .then((res) => {
        setInquirylistdata(res.data.data);
      });
  }

  useEffect(() => {
    ViewInquirys();
  }, [Inquirylistdata]);

  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />
            <main className="flex-grow p-6">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Inquiry List
                </h4>
              </div>

              <div className="mt-6">
                <div className="card">
                  <div className="relative overflow-x-auto">
                    <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
                      <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Phone
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Subject
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Message
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Date
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700 ">
                        {/* {Inquirylistdata?.map((Inquiry) => ( */}
                        {currentRecords?.map((Inquiry) => (
                          <tr>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.id}
                            </td>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.name}
                            </td>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.email}
                            </td>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.phone}
                            </td>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.subject}
                            </td>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.message}
                            </td>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {Inquiry.datetime}
                            </td>

                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              {" "}                             
			    <button
                                type="button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this Inquiry?"
                                    )
                                  ) {
                                    deleteInquiry(Inquiry._id);
                                  }
                                }}
                                className="ms-0.5"
                              >
                                <i className="mgc_delete_line text-xl" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {currentRecords.length >= 10 && (
                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &#x2190;
                  </button>
                  <button
                    className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90 ms-2"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={indexOfLastRecord >= Inquirylistdata.length}
                  >
                    &#x2192;
                  </button>
                </div>
              )}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
