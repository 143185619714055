import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

export default function Footer() {


  return (
    <>
      <footer className="footer h-16 items-center bg-white"> <br/>
        <center>
          © Copyright 2023 <b>Architech</b> all rights reserved.
        </center>
      </footer>
    </>
  );
}
