import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard | Amin Panel";
  }, []);

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
    if (!user_name) {
      location("/");
    }
  });

  const [portfoliolistdatalength, setportfoliolistdatalength] = useState([]);

  function totalPortfolio() {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "portfolio",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("portfolio data length", res.data.dataLength);
        setportfoliolistdatalength(res.data.dataLength);
      });
  }

  useEffect(() => {
    totalPortfolio();
  }, [portfoliolistdatalength]);

  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />

            <div>
              <div
                id="topbar-search-modal"
                className="fc-modal hidden w-full h-full fixed top-0 start-0 z-50"
              >
                <div className="fc-modal-open:opacity-100 fc-modal-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-12 sm:mx-auto">
                  <div className="mx-auto max-w-2xl overflow-hidden rounded-xl bg-white shadow-2xl transition-all dark:bg-slate-800">
                    <div className="relative">
                      <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
                        <i className="mgc_search_line text-xl" />
                      </div>
                      <input
                        type="search"
                        className="h-12 w-full border-0 bg-transparent ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <main className="flex-grow p-6">
              {/* Page Title Start */}
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Dashboard
                </h4>
              </div>
              <div className="grid 2xl:grid-cols-4 gap-6 mb-6">
                <div className="2xl:col-span-3">
                  <div className="grid xl:grid-cols-12 md:grid-cols-1 gap-6 mb-6">
                    
                    <div className="card">
                      <div className="p-6">
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="text-base mb-1 text-gray-600 dark:text-gray-400">
                              Total Portfolio
                            </h4>
                            <p className="font-normal text-sm text-gray-400 truncate dark:text-gray-500">
                              {portfoliolistdatalength}
                            </p>
                          </div>
                          <div>
                            <Link to="/portfolio-list"
                              className="text-gray-600 dark:text-gray-400"
                              data-fc-type="dropdown"
                              data-fc-placement="left-start"
                              type="button"
                            >
                              View All
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
