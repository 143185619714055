
import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function Gallery() {
  useEffect(() => {
    document.title = "Gallery List | Amin Panel";
  }, []);

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
    if (!user_name) {
      location("/");
    }
  });

  const [gallerylistdata, setgallerylistdata] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = gallerylistdata?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  function ViewGallery() {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "gallery",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("View Gallery List Data", res.data.dataLength);
        setgallerylistdata(res.data.data);
      });
  }

  function deleteGallery(id) {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "gallery",
        submethod: "delete",
        key: process.env.REACT_APP_KEY,
        id: id,
      })
      .then((res) => {
        setgallerylistdata(res.data.data);
      });
  }

  useEffect(() => {
    ViewGallery();
  }, [gallerylistdata]);

  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />
            <main className="flex-grow p-6">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Gallery Images List 
                </h4>
              </div>

              <div className="mt-6">
                <div className="card">
                  <div className="flex flex-wrap justify-between items-center gap-2 p-6">
                    <Link
                      to="/add-gallery"
                      className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90"
                    >
                      <i class="mgc_add_circle_line me-3"></i> Add Gallery Image
                    </Link>
                  </div>
                  <div className="relative overflow-x-auto">
                    <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
                      <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700 ">
                       {currentRecords?.map((gallery) => (
                          <tr>
                            <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                              <b>{gallery.id}</b>
                            </td>
                            <td className="whitespace-nowrap py-4 pe-3 text-sm">
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={
                                      `${process.env.REACT_APP_BACKEND_URL}` +
                                      "uploads/" +
                                      `${gallery.image}`
                                    }                                   
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {gallery.status ? (
                                <div className="inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium btn bg-success text-white">
                                  Active
                                </div>
                              ) : (
                                <div className="inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium btn bg-danger text-white">
                                  Deactive
                                </div>
                              )}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-center text-sm font-medium">
                              <button
                                type="button"
                                onClick={() =>
                                  location("/edit-gallery", {
                                    state: { id: gallery._id },
                                  })
                                }
                                className="me-0.5"
                              >
                                {" "}
                                <i className="mgc_edit_line text-lg" />{" "}
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this gallery image?"
                                    )
                                  ) {
                                    deleteGallery(gallery._id);
                                  }
                                }}
                                className="ms-0.5"
                              >
                                <i className="mgc_delete_line text-xl" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {currentRecords?.length >= 10 && (
                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &#x2190;
                  </button>
                  <button
                    className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90 ms-2"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={indexOfLastRecord >= gallerylistdata.length}
                  >
                    &#x2192;
                  </button>
                </div>
              )}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
