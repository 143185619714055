import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function AddPortfolio() {
  useEffect(() => {
    document.title = "Add Portfolio | Amin Panel";
  }, []);

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
    if (!user_name) {
      location("/");
    }
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const [portfolioname, setportfolioname] = useState("");
  const [description, setdescription] = useState("");

  const [portfolioerror, setportfolioerror] = useState("");
  const [descriptionerror, setdescriptionerror] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (portfolioname === "") {
      if (portfolioname === "") {
        setportfolioerror("Please enter a value");
      } else {
        setportfolioerror("");
      }

      if (description === "") {
        setdescriptionerror("Please enter a value");
      } else {
        setdescriptionerror("");
      }
    } else {
      const formData = new FormData();

      formData.append("method", "portfolio");
      formData.append("submethod", "insert");
      formData.append("portfolio_name", portfolioname);
      formData.append("testImage", selectedFile);
      formData.append("testImage2", selectedFile2);
      formData.append("description", description);

      const headers = {
        Authorization: `${process.env.REACT_APP_KEY}`,
        "Content-Type": "multipart/form-data",
      };

      location("/portfolio-list");
      axios
        .post(`${process.env.REACT_APP_BACKEND_LINK}`, formData, { headers })
        .then((response) => {
          console.log(response.data);
          location("/portfolio-list");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />
            <main className="flex-grow p-6">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Add Portfolio
                </h4>
              </div>

              <div className="mt-6">
                <div className="card">
                  <div className="p-6">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Portfolio Name *
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        placeholder="Enter Portfolio Name *"
                        onChange={(e) => setportfolioname(e.target.value)}
                      />
                      <span className="text-danger">{portfolioerror}</span>
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Description *
                      </label>
                      <textarea
                        rows={3}
                        cols={10}
                        className="form-input"
                        placeholder="Enter Portfolio Description *"
                        onChange={(e) => setdescription(e.target.value)}
                      />
                      <span className="text-danger">{descriptionerror}</span>
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Image *
                      </label>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <br />
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Image2 *
                      </label>
                      <input
                        type="file"
                        onChange={handleFileChange2}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <br />
                    <div className="flex justify-center mb-6">
                      <button
                        className="btn w-full text-white bg-primary"
                        onClick={(event) => handleSubmit(event)}
                      >
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
