import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./Components/Home/Home";
import Dashboard from "./Components/Dashboard/Dashboard";
import Gallery from "./Components/Gallery/Gallery";
import AddGallery from "./Components/Gallery/AddGallery";
import EditGallery from "./Components/Gallery/EditGallery";
import VideoGallery from "./Components/VideoGallery/VideoGallery";
import AddVideoGallery from "./Components/VideoGallery/AddVideoGallery";
import EditVideoGallery from "./Components/VideoGallery/EditVideoGallery";
import Portfolio from "./Components/Portfolio/Portfolio";
import AddPortfolio from "./Components/Portfolio/AddPortfolio";
import EditPortfolio from "./Components/Portfolio/EditPortfolio";
import EditProfile from "./Components/Profile/EditProfile";
import Inquiry from "./Components/Inquiry/Inquiry";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/gallery-list" element={<Gallery />} />
          <Route exact path="/add-gallery" element={<AddGallery />} />
          <Route exact path="/edit-gallery" element={<EditGallery />} />
          <Route exact path="/videogallery-list" element={<VideoGallery />} />
          <Route exact path="/add-videogallery" element={<AddVideoGallery />} />
          <Route exact path="/edit-videogallery" element={<EditVideoGallery />} />
          <Route exact path="/portfolio-list" element={<Portfolio />} />
          <Route exact path="/add-portfolio" element={<AddPortfolio />} />
          <Route exact path="/edit-portfolio" element={<EditPortfolio />} />   
          <Route exact path="/profile" element={<EditProfile />} />       
          <Route exact path="/inquiry-list" element={<Inquiry />} />               

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
