import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Home() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const [error, seterror] = useState("");
  const [usererror, setusererror] = useState("");
  const [passworderror, setpassworderror] = useState("");

  const location = useNavigate();

  useEffect(() => {
    document.title = "Login | Amin Panel";
  }, []);

  const checkLogin = async (e) => {
    if (username === "" && password === "") {
      setpassworderror("Enter Username and Password");
    } else if (username === "") {
      setusererror("Enter Username");
      setpassworderror("");
    } else if (password === "") {
      setpassworderror("Enter Password");
      setusererror("");
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
          method: "adminLogin",
          submethod: "check",
          key: process.env.REACT_APP_KEY,
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.data === 1) {
            window.sessionStorage.setItem("qerW9UdE885LKHdW", "ONqUlF");
            window.sessionStorage.setItem("user_name", username);
            setpassworderror("");
            location("/dashboard");
          } else {
            setpassworderror("Username/Password is/or Incorrect");
            setusererror("");
            window.sessionStorage.setItem("qerW9UdE885LKHdW", "ONqUlA");
          }
        });
    }
  };

  return (
    <>
      <div className="bg-gradient-to-r from-rose-100 to-teal-100 dark:from-gray-700 dark:via-gray-900 dark:to-black">
        <div className="h-screen w-screen flex justify-center items-center">
          <div className="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
            <div className="card overflow-hidden sm:rounded-md rounded-none">
              <div className="p-6">
                <Link to="/" className="block mb-8">
                  <img
                    className="block dark:hidden logowidth"
                    src="assets/images/logo.png"
                    alt="Admin Panel" 
                  />
                  <img
                    className="hidden dark:block logowidth"
                    src="assets/images/logo.png"
                    alt="Admin Panel"
                  />
                </Link>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2"
                    htmlFor="LoggingEmailAddress"
                  >
                    Username *
                  </label>
                  <input
                    id="LoggingEmailAddress"
                    className="form-input"
                    type="text"
                    placeholder="Enter your username *"
                    onChange={(e) => setusername(e.target.value)}
                    required
                  />
                  <span className="text-danger">{usererror}</span>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2"
                    htmlFor="loggingPassword"
                  >
                    Password *
                  </label>
                  <input
                    id="loggingPassword"
                    className="form-input"
                    type="password"
                    placeholder="Enter your password *"
                    onChange={(e) => setpassword(e.target.value)}
                    required
                  />
                  <span className="text-danger">{passworderror}</span>
                </div>
                <div className="flex justify-center mb-6">
                  <button
                    className="btn w-full text-white bg-primary"
                    onClick={checkLogin}
                  >
                    {" "}
                    Log In{" "}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
