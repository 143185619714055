import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";

export default function Header() {

  var location = useNavigate();
  
  var user_name = sessionStorage.getItem("user_name");

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {}, [isOpen]);

  const btnLogout = () => {
      sessionStorage.clear();
      location('/');
  }

  return (
    <>
      {/* Topbar Start */}
      <header className="app-header flex items-center px-4 gap-3">
        {/* Sidenav Menu Toggle Button */}
        <button id="button-toggle-menu" className="nav-link p-2">
          <span className="sr-only">Menu Toggle Button</span>
          <span className="flex items-center justify-center h-6 w-6">
            <i className="mgc_menu_line text-xl" />
          </span>
        </button>
        {/* Topbar Brand Logo */}
        <Link to="/" className="logo-box">
          {/* Light Brand Logo */}
          <div className="logo-light">
            <img src="assets/images/logo-light.png" alt="Light logo" />
          </div>
          {/* Dark Brand Logo */}
          <div className="logo-dark">
            <img src="assets/images/logo-dark.png" alt="Dark logo" />
          </div>
        </Link>

        <button
          type="button"
          data-fc-type="modal"
          data-fc-target="topbar-search-modal"
          className="nav-link p-2 me-auto"
        ></button>

        <div className="md:flex hidden">
          <Link to="/profile"
            data-toggle="fullscreen"
            type="button"
            className="nav-link p-2"
          >
            <span className="flex items-center justify-center">Profile</span>
          </Link>
        </div>
        <div className="relative md:flex hidden">
          <button
            data-fc-type="dropdown"
            data-fc-placement="bottom-end"
            type="button"
            className="nav-link p-2"
            onClick={btnLogout}
          >
            <span className="flex items-center justify-center h-6 w-6">
              Logout
            </span>
          </button>
        </div>
      </header>
    </>
  );
}
