import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import axios from "axios";

import { Link } from "react-router-dom";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function EditProfile() {
  useEffect(() => {
    document.title = "Edit Profile | Amin Panel";
  }, []);

  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [error, seterror] = useState('');

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
      if (!user_name) {
        location('/');
      }
  }, []);

  const btnupdate = async (e) => {            
    if (password !== '') {
        axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "adminLogin", submethod: "update", newpassword: password, key: process.env.REACT_APP_KEY })
            .then(res => {
                if (res.data === 0) {
                    seterror("Data Updated Successfully");
                    sessionStorage.clear();
                    location('/');

                } else {
                    seterror("Error Occured");
                }
            })
    }
    else {
        seterror("Please Enter New Password");
    }
}


  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />
            <main className="flex-grow p-6">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Edit Profile
                </h4>
              </div>

              <div className="mt-6">
                <div className="card">
                  <div className="p-6">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Username * (Readonly)
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        placeholder="Enter Username *"
                        defaultValue={user_name} disabled
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        New Password *
                      </label>
                      <input
                        className="form-input"
                        type="password"
                        placeholder="Enter New Password *"
                        onChange={(e) => setpassword(e.target.value)} required
                      />
                    </div>
                   
                    <div className="flex justify-center mb-6">
                      <button className="btn w-full text-white bg-primary" onClick={btnupdate}>
                        {" "}
                        Update{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
