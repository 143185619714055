import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {}, [isOpen]);

  return (
    <>
      {/* Sidenav Menu */}
      <div className="app-menu">
        {/* Sidenav Brand Logo */}
        <Link to="/" className="logo-box">
          {/* Light Brand Logo */}
          <div className="logo-light">
            <img
              className="logowidth"
              src="assets/images/logo.png"
              alt="Admin Panel"
            />
            <img
              src="assets/images/logo.png"
              className="logo-sm logowidth"
              alt="Admin Panel"
            />
          </div>

          <div className="logo-dark">
            <img
              src="assets/images/logo.png"
              className="logowidth"
              alt="Admin Panel"
            />
            <img
              src="assets/images/logo.png"
              className="logo-sm logowidth"
              alt="Admin Panel"
            />
          </div>
        </Link>
        {/* Sidenav Menu Toggle Button */}
        <button
          id="button-hover-toggle"
          className="absolute top-5 end-2 rounded-full p-1.5"
        >
          <span className="sr-only">Menu Toggle Button</span>
          <i className="mgc_round_line text-xl" onClick={toggleMenu} />
        </button>
        {/*- Menu */}
        <div className="srcollbar" data-simplebar>
          <ul className="menu">
            <br />
            <li className="menu-item">
              <Link to="/dashboard" className="menu-link">
                <span className="menu-icon">
                  <i className="mgc_home_3_line" />
                </span>
                <span className="menu-text"> Dashboard </span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/gallery-list" className="menu-link">
                <span className="menu-icon">
                  <i className="mgc_list_check_line" />
                </span>
                <span className="menu-text">Image Gallery </span>
              </Link>
            </li>

            <li className="menu-item">
              <Link to="/videogallery-list" className="menu-link">
                <span className="menu-icon">
                  <i className="mgc_list_check_line" />
                </span>
                <span className="menu-text">Video Gallery </span>
              </Link>
            </li>

            <li className="menu-item">
              <Link to="/portfolio-list" className="menu-link">
                <span className="menu-icon">
                  <i className="mgc_list_check_line" />
                </span>
                <span className="menu-text"> Portfolio </span>
              </Link>
            </li>

            <li className="menu-item">
              <Link to="/inquiry-list" className="menu-link">
                <span className="menu-icon">
                  <i className="mgc_list_check_line" />
                </span>
                <span className="menu-text"> Inquiry </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
