import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate, useLocation } from "react-router-dom";

import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

export default function EditProduct() {
  useEffect(() => {
    document.title = "Edit Gallery | Amin Panel";
  }, []);

  const uselocation = useLocation();

  const location = useNavigate();
  var user_name = sessionStorage.getItem("user_name");

  useEffect(() => {
    if (!user_name) {
      location("/");
    }
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const [statuserror, setstatuserror] = useState("");

  const [gallerydata, setgallerydata] = useState({
    status: "",
    file: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setgallerydata({ ...gallerydata, [name]: value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const { name, value } = event.target;
    setgallerydata({ ...gallerydata, [name]: value });
  };

  const updateGallery = (event) => {
    event.preventDefault();

    if (gallerydata.status === "") {
      if (gallerydata.status === "") {
        setstatuserror("Please select any option");
      } else {
        setstatuserror("");
      }
    } else {
      if (selectedFile) {
        // Create FormData object
        const formData = new FormData();
        formData.append("testImage", selectedFile);

        formData.append("method", "gallery");
        formData.append("submethod", "update");
        formData.append("id", uselocation.state.id);
        formData.append("status", gallerydata.status);

        const headers = {
          Authorization: `${process.env.REACT_APP_KEY}`,
          "Content-Type": "multipart/form-data",
        };

        // Make API request
        axios
          .post(`${process.env.REACT_APP_BACKEND_LINK}`, formData, { headers })
          .then((response) => {
            // Handle successful response
            console.log(response.data);
            location("/gallery-list");
          })
          .catch((error) => {
            // Handle error
            console.error(error);
          });
      } else {
        axios
          .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            method: "gallery",
            submethod: "update",
            id: uselocation.state.id,
            status: gallerydata.status,
            key: process.env.REACT_APP_KEY,
          })
          .then((response) => {
            // Handle successful response
            console.log(response.data);
            location("/gallery-list");
          })
          .catch((error) => {
            // Handle error
            console.error(error);
          });
      }
    }
  };

  const GetGalleryListData = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "gallery",
        submethod: "getbyid",
        id: uselocation.state.id,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("Gallery List Data is", res.data.data);
        if (res.data.data) {
          setgallerydata(res.data.data);
        }
      });
  };
  useEffect(() => {
    GetGalleryListData();
  }, []);

  return (
    <>
      <div>
        <div className="flex wrapper">
          <Sidebar />

          <div className="page-content">
            <Header />
            <main className="flex-grow p-6">
              <div className="flex justify-between items-center mb-6">
                <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
                  Edit Gallery Image
                </h4>
              </div>

              <div className="mt-6">
                <div className="card">
                  <div className="p-6">
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Image
                      </label>
                      <input
                        type="file"
                        name="file"
                        onChange={handleFileChange}
                        accept="image/png, image/jpeg"
                      />
                      <br />
                      <br />

                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          `${process.env.REACT_APP_BACKEND_URL}` +
                          "uploads/" +
                          `${gallerydata.image}`
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2">
                        Status *
                      </label>
                      <select name="status" onChange={handleChange}>
                        <option
                          value="true"
                          selected={gallerydata.status === true}
                        >
                          Active
                        </option>
                        <option
                          value="false"
                          selected={gallerydata.status === false}
                        >
                          Inactive
                        </option>
                      </select>
                    </div>

                    <div className="flex justify-center mb-6">
                      <button
                        className="btn w-full text-white bg-primary"
                        onClick={updateGallery}
                      >
                        {" "}
                        Update{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
